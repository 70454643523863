<template>
    <div class="memberInfo">
        <h2>
            Teachers 教师
        </h2>
        <el-row class="teachers" :gutter="30">
            <!-- span栅格占据的列数，offset栅格左侧的间隔格数:offset="index > 0 ? 0 : 0
            :xs={span:12}屏幕小于768px时显示，span变为12,sm:>768;md:>992；lg:>1200 -->
            <!-- 名人名言栏保留 -->
            <!-- <el-col :md={span:8} :xs={span:24} :sm={span:12}>
                <h3>
                    Teachers 教师
                </h3>
                <p>
                    “If we knew what we were doing, it wouldn't be called research, would it?”<br>———爱因斯坦
                </p>
            </el-col> -->
            <el-col :md={span:8} v-for="(teacher, index) in teachers" :key="index"
                :xs={span:24} :sm={span:12}>
                <!-- shadow="hover" 悬浮鼠标时出现阴影 -->
                <el-card shadow="hover" :body-style="{ padding: '0px' }"
                    style="background-color: rgba(218, 242, 280, 0.24)">
                    <div class="card-left">
                        <img :src="teacher.imgUrl" class="image">
                    </div>
                    <!-- 卡片右边内容 -->
                    <!-- 上半部分 -->
                    <div class="infoDetail">
                        <div class="grade">
                            <img :src="gradeUrl"
                                style="vertical-align:middle;
                                width:18px;
                                height:18px;
                                padding:0 8px 0 0">
                            <span style="vertical-align:middle">
                                {{teacher.grade}}
                            </span>
                        </div>
                        <div style="border-top: 1px solid #dedede">
                            <div style="margin-top: 5px">
                                <!-- 点击名字打开个人主页 -->
                                <a class="infoTitle" :href="teacher.pageUrl" target="_blank">
                                    <!-- {{teacher.nameEng}}<br> -->
                                    {{teacher.nameCh}}
                                </a>
                            </div>
                            
                            <div class="infoSubTitle">{{teacher.major}}</div>                                
                        </div>
                    </div>
                    <!-- 邮箱 -->
                    <div class="email">
                        <!-- img样式使其与右侧文字居中 -->
                        <img :src="emailUrl"
                            style="vertical-align:middle;
                            width:20px;
                            height:20px;
                            padding:0 8px 0 0">
                        <span>{{teacher.email}}
                        </span>      
                    </div>
                    <div class="clearfix"></div>
                </el-card>
            </el-col>
        </el-row>




        

        <!-- 分割线 -->
        <div class="dividing-lines"></div>

        <!-- el-row的gutter 属性来指定每一栏之间的间隔 -->
        <h2>
            Students 学生
        </h2>
        <el-row class="students" :gutter="30">
            <!-- <el-col :md={span:8} :xs={span:24} :sm={span:12}>
                <h3>
                    Students 学生
                </h3>
                <p>
                    “以梦为马，莫负韶华”<br>———海子
                </p>
            </el-col> -->
            <!-- span栅格占据的列数，offset栅格左侧的间隔格数:offset="index > 0 ? 0 : 0
            :xs={span:12}屏幕小于768px时显示，span变为12,sm:>768;md:>992；lg:>1200 -->
            <el-col :md={span:8} v-for="(student, index) in students" :key="index"
                :xs={span:24} :sm={span:12}>
                <!-- shadow="hover" 悬浮鼠标时出现阴影 -->
                <el-card shadow="hover" :body-style="{ padding: '0px' }"
                    style="background-color: rgba(218, 242, 280, 0.24)">
                    <!-- 卡片左边，人物图片 -->
                    <div class="card-left">
                        <img :src="student.imgUrl" class="image">
                    </div>
                    <!-- 卡片右边内容 -->
                    <!-- 上半部分 -->
                    <div class="infoDetail">
                        <!-- 年级/任教 -->
                        <div class="grade">
                            <img :src="gradeUrl"
                                style="vertical-align:middle;
                                width:18px;
                                height:18px;
                                padding:0 8px 0 0">
                            <span style="vertical-align:middle">
                                {{student.grade}}
                            </span>
                        </div>
                        <!-- 名片右边中间部分 -->
                        <div style="border-top: 1px solid #dedede">
                            <!-- 个人姓名及超链接 -->
                            <div style="margin-top: 5px">
                                <!-- 点击名字打开个人主页 -->
                                <a class="infoTitle" :href="student.pageUrl" target="_blank">
                                    <!-- {{student.nameEng}}<br> -->
                                    {{student.nameCh}}
                                </a>
                            </div>
                            <!-- 个人研究方向 -->
                            <div class="infoSubTitle">{{student.major}}</div>                                
                        </div>
                    </div>
                    <!-- 邮箱 -->
                    <div class="email">
                        <!-- img样式vertical-align使其与右侧文字居中 -->
                        <img :src="emailUrl"
                            style="vertical-align:middle;
                            width:20px;
                            height:20px;
                            padding:0 8px 0 0">
                        <span>
                            {{student.email}}
                        </span>
                    </div>
                    <div class="clearfix"></div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
const MS1 = "MS-1Y 研一";
const MS2 = "MS-2Y 研二";
const MS3 = "MS-3Y 研三";
const PHD1 = "PhD-1Y 博一";
const PHD2 = "PhD-2Y 博二";
const PHD3 = "PhD-3Y 博三";
const PHD4 = "PhD-4Y 博四";
const PHD5 = "PhD-5Y 博五";
const PHD6 = "PhD-6Y 博六";
const MP = "MP 大四";
export default {
    name: "Member",
    data() {
        return {
            MS1: "MS-1Y 研一",
            MS2: "MS-2Y 研二",
            MS3: "MS-3Y 研三",
            PHD1: "PhD-1Y 博一",
            PHD2: "PhD-2Y 博二",
            PHD3: "PhD-3Y 博三",
            PHD4: "PhD-4Y 博四",
            PHD5: "PhD-5Y 博五",
            PHD6: "PhD-6Y 博六",
            MP: "MP 大四",
            
            teachers: [
                {
                    nameCh: "宁兆龙",
                    nameEng: "Zhaolong Ning",
                    grade: "教授",
                    major: "智能网络，通信网安全，无人机网络",
                    email: "ningzl@cqupt.edu.cn",
                    pageUrl: "https://faculty.cqupt.edu.cn/ningzhaolong/zh_CN/index/107933/list/index.htm",
                    imgUrl: "http://link.lins-cqupt.cn/teacher/宁兆龙.jpg"
                },
                {
                    nameCh: "宋清洋",
                    nameEng: "Qingyang Song",
                    grade: "教授",
                    major: "移动网络资源管理，边缘计算，车联网，智能反射面",
                    email: "songqy@cqupt.edu.cn",
                    pageUrl: "https://faculty.cqupt.edu.cn/qysong/zh_CN/index.htm",
                    imgUrl: "http://link.lins-cqupt.cn/teacher/宋清洋.jpg"
                },
                {
                    nameCh: "王小洁",
                    nameEng: "Xiaojie Wang",
                    grade: "教授",
                    major: "无人机网络，边缘智能",
                    email: "wangxj@cqupt.edu.cn",
                    pageUrl: "https://faculty.cqupt.edu.cn/wangxiaojie1/zh_CN/index.htm",
                    imgUrl: "http://link.lins-cqupt.cn/teacher/王小洁.jpg"
                },
                {
                    nameCh: "熊炫睿",
                    nameEng: "Xuanrui Xiong",
                    grade: "副教授",
                    major: "数据建模分析，移动泛在网络应用，深度学习与人工智能应用",
                    email: "xiongxr@cqupt.edu.cn",
                    pageUrl: "https://faculty.cqupt.edu.cn/xiongxr/zh_CN/index.htm",
                    imgUrl: "http://link.lins-cqupt.cn/teacher/熊炫睿.jpg"
                },
                {
                    nameCh: "黄一航",
                    nameEng: "Yihang Huang",
                    grade: "文峰副教授",
                    major: "智能无线通信",
                    email: "huangyihang@cqupt.edu.cn",
                    pageUrl: "",
                    imgUrl: "http://link.lins-cqupt.cn/teacher/%E9%BB%84%E4%B8%80%E8%88%AA1.jpg"
                },
                {
                    nameCh: "亓伟敬",
                    nameEng: "Weijing Qi",
                    grade: "讲师",
                    major: "边缘智能，纠删编码容错技术",
                    email: "qiwj@cqupt.edu.cn",
                    pageUrl: "",
                    imgUrl: "http://link.lins-cqupt.cn/teacher/亓伟敬.jpg"
                },
                {
                    nameCh: "吴宇",
                    nameEng: "Yu Wu",
                    grade: "讲师",
                    major: "分布式存储安全，车载安全，无线资源分配",
                    email: "wuy@cqupt.edu.cn",
                    pageUrl: "",
                    imgUrl: "http://link.lins-cqupt.cn/teacher/%E5%90%B4%E5%AE%87.jpg"
                },
                {
                    nameCh: "易令",
                    nameEng: "Ling Yi",
                    grade: "讲师",
                    major: "机器学习，深度学习，可解释机器学习，数据挖掘",
                    email: "yiling@cqupt.edu.cn",
                    pageUrl: "",
                    imgUrl: "http://link.lins-cqupt.cn/teacher/%E6%98%93%E4%BB%A4.jpg"
                },
            ],
            students: [
                /* 博五 */

                /*已毕业*/
                // {
                //     nameCh: "管莹莹",
                //     nameEng: "Yingying Guan",
                //     grade: PHD6,
                //     major: "Network virtualization, Mobile Computing",
                //     email: "yyingguan@126.com",
                //     imgUrl: "http://link.lins-cqupt.cn/student/管莹莹.jpg"
                // },
                // {
                //     nameCh: "孙巍",
                //     nameEng: "Wei Sun",
                //     grade: PHD6,
                //     major: "Reconfigurable Intelligent Surface, Simultaneous Wireless Information and Power Transfer",
                //     email: "sunweivv@foxmail.com",
                //     imgUrl: "http://link.lins-cqupt.cn/student/孙巍.jpg"
                // },

                /* 博四 */
                // {
                //     nameCh: "宋静",
                //     nameEng: "Jing Song",
                //     grade: PHD5,
                //     major: "Virtual Reality, Deep Reinforcement Learning, Blockchain",
                //     email: "singjingchn@foxmail.com",
                //     imgUrl: "http://link.lins-cqupt.cn/student/宋静.jpg"
                // },
                
                // {
                //     nameCh: "吴梦如",
                //     nameEng: "Mengru WU",
                //     grade: PHD5,
                //     major: "Wireless Power Transfer，Edge Computing",
                //     email: "mengruwu@foxmail.com",
                //     imgUrl: "http://link.lins-cqupt.cn/student/吴梦如.jpg"
                // },
                /* 博三 */
                {
                    nameCh: "杨震",
                    nameEng: "Zhen Yang",
                    grade: "博三",
                    major: "联邦学习网络",
                    email: "yangzhencupt@foxmail.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/杨震.jpg"
                },
                {
                    nameCh: "康亚",
                    nameEng: "Ya Kang",
                    grade: "博三",
                    major: "资源管理",
                    email: "d210101008@stu.cqupt.edu.cn",
                    imgUrl: "http://link.lins-cqupt.cn/student/康亚.jpg"
                },
                {
                    nameCh: "刘雅婷",
                    nameEng: "Yating Liu",
                    grade: "博三",
                    major: "物联网的可靠通信和计算",
                    email: "lusinda_liu@163.com ",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E5%88%98%E9%9B%85%E5%A9%B7.jpg"
                },
                /* 博二 */
                {
                    nameCh: "成奎",
                    nameEng: "Kui Cheng",
                    grade: "博二",
                    major: "智能网络安全",
                    email: "1334487410@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E6%88%90%E5%A5%8E.jpg"
                },
                /* 博一 */
                {
                    nameCh: "王鑫辉",
                    nameEng: "Xinhui Wang",
                    grade: "博一",
                    major: "智能通信，网络信息安全",
                    email: "286582139@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E7%8E%8B%E9%91%AB%E8%BE%89.png"
                },
                {
                    nameCh: "张庆楼",
                    nameEng: "Qinglou Zhang",
                    grade: "博一",
                    major: "算力网络，资源分配",
                    email: "d240101038@stu.cqupt.edu.cn",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E5%BC%A0%E5%BA%86%E6%A5%BC.jpg"
                },

                // /* 研三 */
                // {
                //     nameCh: "陈维熙",
                //     nameEng: "Weixi Chen",
                //     grade: "MS-3Y 研三",
                //     major: "Wirelees Virtual reality， Mobile Edge Computing",
                //     email: "18580572725@163.com",
                //     imgUrl: "http://link.lins-cqupt.cn/student/陈维熙.jpg"
                // },
                // {
                //     nameCh: "朱泽林",
                //     nameEng: "Zelin Zhu",
                //     grade: "MS-3Y 研三",
                //     major: "5G Air Interface Waveform",
                //     email: "drewzhu@foxmail.com",
                //     imgUrl: "http://link.lins-cqupt.cn/student/朱泽林.jpg"
                // },


                // /* 研三 2023已毕业 */
                /* {
                    nameCh: "豆舒楠",
                    nameEng: "Shunan Dou",
                    grade: MS3,
                    major: "Symbiotic Radio，Intelligent Reflecting Surface",
                    email: "1009608590@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/豆舒楠.jpg"
                },
                {
                    nameCh: "廖延静",
                    nameEng: "Yanjing Liao",
                    grade: MS3,
                    major: "Resource Allocation",
                    email: "3430007832@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/廖延静.jpg"
                },
                {
                    nameCh: "田照云",
                    nameEng: "Zhaoyun Tian",
                    grade: MS3,
                    major: "IRS，WPCN security",
                    email: "1041786560@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/田照云.jpg"
                },
                {
                    nameCh: "王辉",
                    nameEng: "Hui Wang",
                    grade: MS3,
                    major: "Symbiotic Radio，IRS，Physical Layer Security",
                    email: "HHWang0921@163.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/王辉.jpg"
                },
                {
                    nameCh: "徐桂喜",
                    nameEng: "Guixi Xu",
                    grade: MS3,
                    major: "UAV，Internet of Vehicle",
                    email: "913241475@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/徐桂喜.jpg"
                },
                {
                    nameCh: "张丽杰",
                    nameEng: "Lijie Zhang",
                    grade: MS3,
                    major: "Mobile Edge Computing",
                    email: "1298256315@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/张丽杰.jpg"
                }, */

                /* 研三 2024已毕业*/
                /*{
                    nameCh: "欧阳瑞崎",
                    nameEng: "Ruiqi Ouyang",
                    grade: MS3,
                    major: "Network Security",
                    email: "ouyangruiqi77@163.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/欧阳瑞崎.jpg"
                },
                {
                    nameCh: "郭琪",
                    nameEng: "Qi Guo",
                    grade: MS3,
                    major: "ITS (intelligent transportation systems)，IoV",
                    email: "guoqicqupt@163.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/郭琪.jpg"
                },
                {
                    nameCh: "李家梦",
                    nameEng: "Jiameng Li",
                    grade: MS3,
                    major: "Unmanned Aerial Vehicles，Mobile Edge Computing，Wireless Power Transfer",
                    email: "krystal_liruan@sina.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E6%9D%8E%E5%AE%B6%E6%A2%A61.jpg"
                },
                {
                    nameCh: "刘贺",
                    nameEng: "He Liu",
                    grade: MS3,
                    major: "UAV ad hoc networking",
                    email: "S210131126@stu.cqupt.edu.cn",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E5%88%98%E8%B4%BA1.jpg"
                },
                {
                    nameCh: "杨彪",
                    nameEng: "Biao Yang",
                    grade: MS3,
                    major: "UAV Ad Hoc Networking",
                    email: "994880908@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E6%9D%A8%E5%BD%AA3.jpg"
                },
                {
                    nameCh: "祝海琳",
                    nameEng: "Hailin Zhu",
                    grade: MS3,
                    major: "BlockChain，Internet of Vehicles",
                    email: "zhuhal10@163.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/祝海琳.jpg"
                },
                {
                    nameCh: "胡浩",
                    nameEng: "Hao Hu",
                    grade: MS3,
                    major: "Machine Learning，MEC，UAV",
                    email: "S210101045@stu.cqupt.edu.cn",
                    imgUrl: "http://link.lins-cqupt.cn/student/胡浩.jpg"
                },
                {
                    nameCh: "王杰",
                    nameEng: "Jie Wang",
                    grade: MS3,
                    major: "Internet of Vehicles Security，BlockChain",
                    email: "1394391863@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/王杰.jpg"
                },
                {
                    nameCh: "邓鶱闯",
                    nameEng: "Xianchuang Deng",
                    grade: MS3,
                    major: "Network Slicing",
                    email: "1097402982@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/邓鶱闯.jpg"
                },
                {
                    nameCh: "李振斌",
                    nameEng: "Zhenbin Li",
                    grade: MS3,
                    major: "Internet of vehicles",
                    email: "397852322@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/李振斌.jpg"
                },
                {
                    nameCh: "李慧",
                    nameEng: "Hui Li",
                    grade: MS3,
                    major: "Cell-free，Intelligent Reflecting Surface",
                    email: "1104095073@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/李慧.jpg"
                },
                {
                    nameCh: "张茂",
                    nameEng: "Mao Zhang",
                    grade: MS3,
                    major: "Cell-free，Intelligent Reflecting Surface",
                    email: "2504178347@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/张茂.jpg"
                },
                {
                    nameCh: "任贵易",
                    nameEng: "Guiyi Ren",
                    grade: MS3,
                    major: "SR，RIS，V2X",
                    email: "1208590991@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/任贵易.jpg"
                },
                {
                    nameCh: "万金鑫",
                    nameEng: "Jinxin Wan",
                    grade: MS3,
                    major: "SR，RIS，V2X",
                    email: "838251031@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/万金鑫.jpg"
                },*/

                //熊老师学生
                /* {
                    nameCh: "沈涵",
                    nameEng: "Han Shen",
                    grade: MS3,
                    major: " Deep Learning，Network intrusion detection",
                    email: " s210131206@stu.cqupt.edu.cn",
                    imgUrl: "http://link.lins-cqupt.cn/student/沈涵.jpg"
                },
                {
                    nameCh: "黄琪",
                    nameEng: "Qi Huang",
                    grade: MS3,
                    major: " Deep Learning，network security",
                    email: "HQ_confident@126.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/黄琪.jpg"
                },
                {
                    nameCh: "彭巍",
                    nameEng: "Wei Peng",
                    grade: MS3,
                    major: " Deep Learning，Network traffic generation",
                    email: " S210131190@stu.cqupt.edu.cn",
                    imgUrl: "http://link.lins-cqupt.cn/student/彭巍.jpg"
                },
                {
                    nameCh: "刘梦茹",
                    nameEng: "Mengru Liu",
                    grade: MS3,
                    major: "Deep Learning，Network traffic analysis",
                    email: "s210131136@stu.cqupt.edu.cn",
                    imgUrl: "http://link.lins-cqupt.cn/student/刘梦茹.jpg"
                }, */
                
                
                
                /* 研三 */
                {
                    nameCh: "黎藤峰",
                    nameEng: "Tengfeng Li",
                    grade: "研三",
                    major: "无人机，智能反射面，6G",
                    email: "2018210121@stu.cqupt.edu.cn",
                    imgUrl: "http://link.lins-cqupt.cn/student/黎藤峰.jpg"
                },
                {
                    nameCh: "赵忠辉",
                    nameEng: "Zhonghui Zhao",
                    grade: "研三",
                    major: "无人机，通信安全",
                    email: "1679656245@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/赵忠辉.png"
                },
                {
                    nameCh: "郑桂锋",
                    nameEng: "Guifeng Zheng",
                    grade: "研三",
                    major: "深度学习，网络安全",
                    email: "zhenggf6@163.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/郑桂锋.jpg"
                },
                {
                    nameCh: "陈子强",
                    nameEng: "Ziqiang Chen",
                    grade: "研三",
                    major: "无人机自组网",
                    email: "2300184296@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/陈子强.jpg"
                },
                {
                    nameCh: "万鑫",
                    nameEng: "Xin Wan",
                    grade: "研三",
                    major: "信令网络安全",
                    email: "1204130156@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/万鑫.jpg"
                },
                {
                    nameCh: "刘子依",
                    nameEng: "Ziyi Liu",
                    grade: "研三",
                    major: "网络安全，区块链，隐私保护",
                    email: "1328037418@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E5%88%98%E5%AD%90%E4%BE%9D.jpg"
                },
                {
                    nameCh: "李晗雪",
                    nameEng: "HanXue Li",
                    grade: "研三",
                    major: "链下网络",
                    email: "1204433965@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/李晗雪.jpg"
                },
                {
                    nameCh: "李腾飞",
                    nameEng: "Tengfei Li",
                    grade: "研三",
                    major: "信令网络安全",
                    email: "1210887554@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/李腾飞.jpg"
                },
                {
                    nameCh: "王贝贝",
                    nameEng: "Beibei Wang",
                    grade: "研三",
                    major: "可信机器学习，边缘计算",
                    email: "1196365188@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/王贝贝.jpg"
                },
                {
                    nameCh: "唐守泽",
                    nameEng: "Shouze Tang",
                    grade: "研三",
                    major: "信令网络安全",
                    email: "1309636623@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/唐守泽.jpg"
                },
                {
                    nameCh: "肖玉龙",
                    nameEng: "Yulong Xiao",
                    grade: "研三",
                    major: "无人机定位",
                    email: "963028157@qq.conpmm",
                    imgUrl: "http://link.lins-cqupt.cn/student/肖玉龙.jpg"
                },
                //熊老师学生
                /* {
                    nameCh: "胡丹",
                    nameEng: "Dan Hu",
                    grade: MS1,
                    major: " UAV",
                    email: "2844726036@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/胡丹.jpg"
                },
                {
                    nameCh: "何梦婷",
                    nameEng: "Mengting He",
                    grade: MS1,
                    major: " UAV",
                    email: "1078850012@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/何梦婷.jpg"
                }, */
                {
                    nameCh: "张玉树",
                    nameEng: "Yushu Zhang",
                    grade: "研三",
                    major: "深度学习，网络入侵检测",
                    email: "1391840292@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/张玉树.jpg"
                },


                // 研二
                {
                    nameCh: "张家伟",
                    nameEng: "Jiawei Zhang",
                    grade: "研二",
                    major: "无人机，无人车与机器人协同组网" ,
                    email: "zhangjw6815@163.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E5%BC%A0%E5%AE%B6%E4%BC%9F.jpg"
                },
                {
                    nameCh: "陈源",
                    nameEng: "Yuan Chen",
                    grade: "研二",
                    major: "移动通信网络威胁效能分析",
                    email: "yuan.yuan_chen@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E9%99%88%E6%BA%90.jpg"
                },
                {
                    nameCh: "陈怡硕",
                    nameEng: "Yishuo Chen",
                    grade: "研二",
                    major: "移动通信网络安全",
                    email: "1367115310@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E9%99%88%E6%80%A1%E7%A1%95.jpg"
                },
                {
                    nameCh: "刘倩雯",
                    nameEng: "Qianwen Liu",
                    grade: "研二",
                    major: "天空天地一体化网络，区块链",
                    email: "3187299091@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E5%88%98%E5%80%A9%E9%9B%AF.jpg"
                },
                {
                    nameCh: "任佳鑫",
                    nameEng: "Jiaxin Ren",
                    grade: "研二",
                    major: "边缘AIGC的安全性",
                    email: "1245961584@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E4%BB%BB%E4%BD%B3%E9%91%AB.jpg"
                },
                {
                    nameCh: "张玉帧",
                    nameEng: "Yuzhen Zhang",
                    grade: "研二",
                    major: "网络空间内生安全",
                    email: "734982352@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E5%BC%A0%E7%8E%89%E5%B8%A7.jpg"
                },
                {
                    nameCh: "邹道远",
                    nameEng: "Daoyuan Zou",
                    grade: "研二",
                    major: "移动通信网络协议的安全性分析",
                    email: "2217967571@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E9%82%B9%E9%81%93%E8%BF%9C.jpg"
                },
                {
                    nameCh: "罗浩",
                    nameEng: "Luo Hao",
                    grade: "研二",
                    major: "对抗性攻击，对抗性机器学习",
                    email: "luohao0222@163.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E7%BD%97%E6%B5%A9.jpg"
                },
                {
                    nameCh: "张涵池",
                    nameEng: "Hanchi Zhang",
                    grade: "研二",
                    major: "无人机、车辆和机器人的协作网络",
                    email: "1178564080@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E5%BC%A0%E6%B6%B5%E6%B1%A0.jpg"
                },
                {
                    nameCh: "郭星佑",
                    nameEng: "Xingyou Guo",
                    grade: "研二",
                    major: "移动通信网络协议安全",
                    email: "guoxingyou2000@163.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E9%83%AD%E6%98%9F%E4%BD%91.jpg"
                },
                {
                    nameCh: "张俊林",
                    nameEng: "Junlin Zhang",
                    grade: "研二",
                    major: "移动通信网络控制平面信令安全",
                    email: "z1679279106@163.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E5%BC%A0%E4%BF%8A%E6%9E%97.jpg"
                },
                {
                    nameCh: "肖磊",
                    nameEng: "Lei Xiao",
                    grade: "研二",
                    major: "网络空间内生安全入侵防御技术",
                    email: "2779159802@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E8%82%96%E7%A3%8A.jpg"
                },

                // 研一
                {
                    nameCh: "何鑫",
                    nameEng: "Xin He",
                    grade: "研一",
                    major: "无人机，智能反射面",
                    email: "2605702732@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E4%BD%95%E9%91%AB.JPG"
                },
                {
                    nameCh: "李昂",
                    nameEng: "Ang Li",
                    grade: "研一",
                    major: "协议漏洞检测",
                    email: "1360074937@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E6%9D%8E%E6%98%82.jpg"
                },
                {
                    nameCh: "李凌飞",
                    nameEng: "Lingfei Li",
                    grade: "研一",
                    major: "天空天地一体化网络",
                    email: "lilingfei02@163.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E6%9D%8E%E5%87%8C%E9%A3%9E.jpg"
                },
                {
                    nameCh: "刘通",
                    nameEng: "Tong Liu",
                    grade: "研一",
                    major: "移动通信网络协议的安全性分析",
                    email: "564376048@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E5%88%98%E9%80%9A.jpg"
                },
                {
                    nameCh: "苏绪文",
                    nameEng: "Xuwen Su",
                    grade: "研一",
                    major: "无人机，信道测量，场景识别",
                    email: "1508123797@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E8%8B%8F%E7%BB%AA%E6%96%87.jpg"
                },
                {
                    nameCh: "田景辉",
                    nameEng: "Jinghui Tian",
                    grade: "研一",
                    major: "加强移动通信网络安全",
                    email: "1470720533@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E7%94%B0%E6%99%AF%E8%BE%89.png"
                },
                {
                    nameCh: "王铁浩",
                    nameEng: "Tiehao Wang",
                    grade: "研一",
                    major: "信令网络安全",
                    email: "1812797136@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E7%8E%8B%E9%93%81%E6%B5%A9.jpg"
                },
                {
                    nameCh: "张砚邱",
                    nameEng: "Yanqiu Zhang",
                    grade: "研一",
                    major: "移动通信网络协议的安全性分析",
                    email: "1814069718@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E5%BC%A0%E7%A0%9A%E9%82%B1.jpg"
                },
                {
                    nameCh: "郑忠",
                    nameEng: "Zhong Zheng",
                    grade: "研一",
                    major: "信令网络安全",
                    email: "729768147@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E9%83%91%E5%BF%A0.jpg"
                },
                {
                    nameCh: "周坤",
                    nameEng: "Kun Zhou",
                    grade: "研一",
                    major: "无人机，信道测量，信道推断",
                    email: "1801643118@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E5%91%A8%E5%9D%A4.jpg"
                },
                {
                    nameCh: "汪子睿",
                    nameEng: "Zirui Wang",
                    grade: "研一",
                    major: "空地信道测量，信道模型开发",
                    email: "wangzr4711@163.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E6%B1%AA%E5%AD%90%E7%9D%BF.jpg"
                },        
                                {
                    nameCh: "沈明广",
                    nameEng: "Mingguan Shen",
                    grade: "研一",
                    major: "无人机，移动边缘计算，无线能量传输",
                    email: "smg01024@163.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E6%B2%88%E6%98%8E%E5%B9%BF.JPG"
                },
                {
                    nameCh: "邱森森",
                    nameEng: "Sensen Qiu",
                    grade: "研一",
                    major: "无人机，信道建模，信道推断",
                    email: "3215579274@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E9%82%B1%E6%A3%AE%E6%A3%AE.jpg"
                },
                {
                    nameCh: "刘梦阳",
                    nameEng: "Mengyang Liu",
                    grade: "研一",
                    major: "无人机信道建模",
                    email: "liumy1101@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E5%88%98%E6%A2%A6%E9%98%B3.jpg"
                },
                {
                    nameCh: "任新宇",
                    nameEng: "Xinyu Ren",
                    grade: "研一",
                    major: "无人机通信",
                    email: "2281985848@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E4%BB%BB%E6%96%B0%E5%AE%87.JPG"
                },
                {
                    nameCh: "陈高",
                    nameEng: "Gao Chen",
                    grade: "研一",
                    major: "地空一体化网络安全及资源任务分配",
                    email: "19981471708@163.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E9%99%88%E9%AB%98.jpg"
                },
                {
                    nameCh: "曹维峰",
                    nameEng: "Weifeng Cao",
                    grade: "研一",
                    major: "5G通信网络安全",
                    email: "2173178061@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E6%9B%B9%E7%BB%B4%E5%B3%B0.jpg"
                },
                // {
                //     nameCh: "成浩",
                //     nameEng: "Hao Cheng",
                //     grade: MS1,
                //     major: "UAV feature extraction and scene recognition",
                //     email: "2404902592@qq.com",
                //     imgUrl: "http://link.lins-cqupt.cn/student/%E6%88%90%E6%B5%A9.jpg"
                // },
                {
                    nameCh: "毛金凤",
                    nameEng: "Jinfeng Mao",
                    grade: "研一",
                    major: "通信网络协议的安全性",
                    email: "3439300350@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E6%AF%9B%E9%87%91%E5%87%A4.jpg"
                },
                {
                    nameCh: "彭梦成",
                    nameEng: "Mengcheng Peng",
                    grade: "本硕博-大四",
                    major: "无人机通信",
                    email: "p_mengcheng@163.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E5%BD%AD%E6%A2%A6%E6%88%90.jpg"
                },
                {
                    nameCh: "沈志成",
                    nameEng: "Zhicheng Shen",
                    grade: "本硕博-大四",
                    major: "无人机通信与信道建模",
                    email: "2015398750@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E6%B2%88%E5%BF%97%E6%88%90.jpg"
                },
                {
                    nameCh: "聂佳",
                    nameEng: "Jia Nie",
                    grade: "本硕博-大四",
                    major: "无线通信",
                    email: "nie.668@163.com",
                    imgUrl: "http://link.lins-cqupt.cn/student/%E8%81%82%E4%BD%B3.jpg"
                },
                {
                    nameCh: "孙培博",
                    nameEng: "Peibo Sun",
                    grade: "本硕博-大四",
                    major: "5G通信网络安全",
                    email: "1790840289@qq.com",
                    imgUrl: "http://link.lins-cqupt.cn/activity/%E5%AD%99%E5%9F%B9%E5%8D%9A.jpg"
                },
            ],
            gradeUrl: "http://link.lins-cqupt.cn/logo/grade-logo.png",
            emailUrl: "http://link.lins-cqupt.cn/logo/email.png",
        }
    }
    
}
</script>

<style scoped>
    .mrmberInfo{
        width: 100%;
        height: 100%;
    }


    /* 分割线 */
    .dividing-lines {
        position: relative;
        height: 55px;
    }
    /* 伪元素画横线 */
    .dividing-lines::after {
        content: '';
        position: absolute;
        left: 0px;
        top: 15px;
        bottom: 40px;
        height: 1px;
        width: 100%;
        background-color: #bbbbbb ;
    }
    
    .el-row {
        justify-content: space-between;
    }    
    .el-row:last-child {
        margin-bottom: 0;
    }
    .el-col {
        border-radius: 4px;
        margin-bottom: 25px;
        text-align: center;
    }
    .el-col:last-child {
        margin-bottom: 0;
    }
    /* 卡片整体样式 */
    .el-card {
        border-color: rgb(233, 233, 233) !important;
        width: 100%;
        float: left;
        display: block;
    }
    /* 强行自定义改卡片背景色 */
/*     .el-card__body {
        background-color: rgba(218, 242, 252, 0.247);
    } */
    /* 卡片左右分布,左边的样式，宽高规定内部图片的大小 */
    .card-left {
        display: block;
        float: left;
        overflow: hidden;
        width: 120px;
        height: 160px;
    }
    /***************************************/
    /* 卡片左边的图片样式,图片需要长宽比4:3大小 */
    .image {
        width: 100%;
        height: 100%;
        display: block;
    }
    /***************************************/
    /* 卡片右边上部分 */
    .infoDetail {
        height: 128px;
        padding: 0px 0 10px 0;
        display: block;
        font-size: 16px;
    }
    .grade {
        color: rgb(87, 87, 87);
        padding: 0 0 5px 0px;
    }
    /* 个人信息名字 */
    .infoTitle {
        line-height: 30px;
        font-weight: 800;
        font-size: 24px;
        /* 去掉超链接下划线 */
        text-decoration: none;
        color: black;
        margin-top: 0px;
    }
    /* 个人信息研究方向 */
    .infoSubTitle{
        line-height: 30px;
        font-size: 18px;
        color: rgb(87, 87, 87);
        padding: 7px 5px ;
    }
    /* 邮箱div样式 */
    .email {
        display: block;
        height: 20px;
        width: 100%;
        padding: 1px 0;
        font-size: 14px;
        color: rgb(87, 87, 87);
        border-top: 1px solid #dedede
    }
    /* 卡片最后清除样式，避免互相影响 */
    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }
    
    .clearfix:after {
        clear: both
    }
</style>